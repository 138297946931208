import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import MainLayout from "../../../../components/mainlayout";
import { navigate } from "gatsby";
import { Row, Col, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import NewOrder from "../../../../components/order/new";
import "bootstrap/scss/bootstrap.scss";

export default function NewOrderForBulkPage(props) {
  const [bulkId, setBulkId] = useState(null);
  useEffect(() => {
    if (props.params.id) {
      setBulkId(props.params.id);
    }
  }, [props.params.id]);
  return (
    <MainLayout>
      <Helmet title="Илгээмж бүртгэх | SB Express" defer={false} />
      <Row className="mb-3">
        <Col span={24}>
          <div style={{ position: "absolute" }}>
            <Button
              type="link"
              className="sb-arrow-back"
              onClick={() => {
                navigate(`/bulk/${bulkId}`);
              }}
            >
              <FontAwesomeIcon icon={faChevronLeft} size="xs" />
              <span>Багц илгээмж рүү буцах</span>
            </Button>
          </div>
          <div className="text-center sb-text-large">Илгээмж нэмэх</div>
        </Col>
      </Row>
      <NewOrder bulkId={bulkId} isBulk={true}/>
    </MainLayout>
  );
}
